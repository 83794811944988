@import '_variables.scss';

.c-notfound {
    height: 100vh;

    position: relative;

    background-position: center left;
    background-size: cover;

    &:before {
        content: '';

        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;

        background-color: rgba(#fff, 0.5);

        pointer-events: none;

        @media (min-width: 1101px) {
            display: none;
        }
    }
}

.c-notfound__logo {
    display: inline-block;
    width: 120px;
    margin: 1rem;

    position: relative;
}

.c-notfound__content {
    max-width: 500px;

    position: absolute;
    top: 50%;
    left: 50%;

    text-align: center;

    @media (max-width: 1100px) {
        width: 100%;
        padding: 2rem;

        transform: translate(-50%, -50%);
    }

    @media (min-width: 1101px) {
        transform: translateY(-50%);
    }

    p {
        margin: 0;

        font-size: 3rem;
    }
}

.c-notfound__title {
    margin: 0;

    color: #da4f3f;
    font-size: 3rem;
    font-weight: 300;
}

.c-notfound__link {
    display: block;
    margin-top: 1rem;

    color: $body-font-color;

    svg {
        margin-bottom: 1rem;

        color: #da4f3f;
        font-size: 3rem;
    }
}
