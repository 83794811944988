@import '_variables.scss';

.c-imageblock {
    position: relative;

    @media(min-width: 751px) {
        margin-bottom: 6rem;
    }
}

.c-imageblock__image {
    display: block;
    width: 100%;
    max-height: 400px;

    object-fit: cover;
}

.c-imageblock__content {
    margin: -3rem 3rem 3rem;
    padding: 3rem;

    position: relative;

    color: #fff;

    @include is-color('background-color');

    @media(min-width: 751px) {
        width: 50%;
        margin: 0;

        position: absolute;
        right: 3rem;
        bottom: -3rem;
    }
}

.c-imageblock__title {
    margin: 0 0 2rem;

    font-size: 2rem;
}
