@import '_variables.scss';

.o-news-archive__articles {
    padding: 3rem;

    @media (min-width: 751px) {
        padding: 4rem;
    }
}

.o-news-archive__load-more {
    display: block;
    margin: 0 auto 4rem;
    padding: 1rem;
    border: none;

    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;

    background-color: $color-blue;

    cursor: pointer;
}
