@import '_variables.scss';

.c-button {
    display: block;
    padding: 0.6rem 1rem 0.3rem;
    border: none;

    color: $body-font-color;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    text-align: left;

    background-color: $color-grey;

    cursor: pointer;

    @include is-color(background-color);

    &:hover {
        text-decoration: none;
    }

    &[class *= 'is-color-'] {
        color: #fff;
    }

    svg {
        margin-top: 2px;
        margin-left: 1rem;
        float: right;
    }

    .c-award &,
    .c-award-modal & {
        width: 100%;
        max-width: 250px;
        margin: 2rem auto 0;
    }
}
