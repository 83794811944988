@import '_variables.scss';

.c-award-modal {
    width: 100%;
    height: 100%;
    overflow: auto;

    position: absolute;

    color: #fff;

    background-position: center;

    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);

    @media (max-width: 750px) {
        padding: 1rem 3rem;
    }

    @media (min-width: 751px) {
        padding: 1rem;
    }
}

.c-award-modal__content {
    max-width: 1200px;
    margin: 0 auto;
}

.c-award-modal__column {
    padding: 0 1rem;

    @media (min-width: 751px) {
        display: inline-block;
        width: calc(50% - 1rem);

        vertical-align: top;
    }
}

.c-award-modal__section {
    margin: 4rem 0;
}

.c-award-modal__logo {
    width: 120px;
}

.c-award-modal__close {
    padding: 0;
    float: right;
    border: none;

    color: #fff;
    font-size: 4rem;

    background: transparent;

    img {
        width: 40px;
    }
}

.c-award-modal__image {
    display: block;
    max-width: 100%;
    margin: 2rem auto;

    @media (min-width: 751px) {
        width: 400px;
    }
}

.c-award-modal__title {
    @media (max-width: 750px) {
        font-size: 2rem;
    }

    @media (min-width: 751px) {
        font-size: 3rem;
    }
}
