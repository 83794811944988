.utl-chevron {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;

    transform-origin: 7px 7px;

    &.utl-chevron--up {
        transform: rotate(45deg);
    }

    &.utl-chevron--down {
        transform: rotate(-135deg);
    }

    &.utl-chevron--left {
        transform: rotate(-45deg);
    }

    &.utl-chevron--right {
        transform: rotate(135deg);
    }
}
