$body-font-color: #333;
$color-red: #cc5848;
$color-blue: #44a0d8;
$color-green: #89ad5c;
$color-grey-light: #f2f2f2;
$color-grey: #c7c7c7;
$color-grey-dark: #666;
$color-grey-darker: #414042;
$color-grey-darkerer: #2c2c2c;
$color-yellow: #f1c251;
$circle-expand-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

@mixin is-color($field) {
    &.is-color-red {
        #{$field}: $color-red;
    }

    &.is-color-green {
        #{$field}: $color-green;
    }

    &.is-color-blue {
        #{$field}: $color-blue;
    }

    &.is-color-yellow {
        #{$field}: $color-yellow;
    }
}

@mixin is-color-parent($field) {
    .is-color-red & {
        #{$field}: $color-red;
    }

    .is-color-green & {
        #{$field}: $color-green;
    }

    .is-color-blue & {
        #{$field}: $color-blue;
    }

    .is-color-yellow & {
        #{$field}: $color-yellow;
    }
}
