@import '_variables.scss';

.c-subpage-header__top {
    padding: 1rem 3rem;

    background-color: #fff;

    @media (min-width: 751px) {
        display: none;
    }
}

.c-subpage-header__logo {
    display: block;
    width: 120px;
}

.c-subpage-header__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0 3rem;

    color: #fff;

    background-position: center;
    background-size: cover;

    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);

    @media (max-width: 750px) {
        min-height: 300px;
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media (min-width: 751px) {
        min-height: 500px;
        padding-right: 4rem;
        padding-left: 4rem;
    }

    a {
        color: #fff;
    }

    .c-subpage-header.c-subpage-header--news-article & {
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
    }
}

.c-subpage-header__back {
    align-self: flex-start;
    margin-bottom: 4rem;

    @media (min-width: 751px) {
        display: none;
    }
}

.c-subpage-header__arrow {
    width: 30px;
    height: 30px;
    margin: 7px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;

    transform: rotate(45deg);
}

.c-subpage-header__breadcrumbs {
    margin-bottom: 2rem;

    @media (max-width: 750px) {
        display: none;
    }

    a {
        color: #fff;
    }
}

.c-subpage-header__current-page {
    @include is-color('color');
}

.c-subpage-header__content {
    @media (min-width: 751px) {
        display: flex;
        justify-content: space-between;
        padding-top: 2rem;
        border-top: 1px solid #fff;
    }

    .c-subpage-header.c-subpage-header--news-archive & {
        display: flex;
        flex-direction: column;
    }

    .c-subpage-header.c-subpage-header--news-article & {
        display: none;
    }
}

.c-subpage-header__title {
    display: block;
    margin: 1rem 0 0;

    position: relative;

    @media (max-width: 750px) {
        margin-bottom: 2rem;

        font-size: 2.5rem;
    }

    @media (min-width: 751px) {
        font-size: 3rem;
    }

    @media (min-width: 1024px) {
        font-size: 5rem;
        line-height: 4rem;
    }

    &:before {
        content: '';

        width: 20px;
        height: 100%;

        position: absolute;
        top: 0;

        @media (max-width: 750px) {
            left: -3rem;
        }

        @media (min-width: 751px) {
            left: -4rem;
        }
    }

    &.is-color-red:before {
        background-color: $color-red;
    }

    &.is-color-green:before {
        background-color: $color-green;
    }

    &.is-color-blue:before {
        background-color: $color-blue;
    }

    &.is-color-yellow:before {
        background-color: $color-yellow;
    }

    .c-subpage-header.c-subpage-header--news-archive & {
        width: 100%;
        margin-bottom: 0;

        font-size: 2rem;
        line-height: 1.15;
        font-weight: normal;
    }
}

.c-subpage-header__description {
    line-height: 1.4;

    .c-subpage-header__title + & {
        @media (min-width: 751px) {
            width: 50%;
            min-width: 50%;
            padding-left: 2rem;
        }
    }

    .c-subpage-header.c-subpage-header--news-archive & {
        order: -1;
        padding-left: 0;

        @include is-color('color');
    }
}
