@import '_variables.scss';

.o-our-people {
    @media (max-width: 750px) {
        padding: 3rem;
    }

    @media (min-width: 751px) {
        padding: 4rem;
    }
}

.o-our-people__headline {
    margin: 0 0 1rem;

    font-size: 3rem;

    @media (min-width: 751px) {
        width: 50%;
        padding-right: 2rem;
    }
}

.o-our-people__people {
    display: flex;

    @media (max-width: 750px) {
        flex-direction: column;
    }

    @media (min-width: 751px) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.c-person {
    @media (min-width: 751px) and (max-width: 1023px) {
        width: 48%;
    }

    @media (min-width: 1024px) {
        width: 30%;
    }

    &:not(:last-child) {
        @media (max-width: 750px) {
            margin-bottom: 2rem;
        }

        @media (min-width: 751px) {
            margin-bottom: 4rem;
        }
    }
}

.c-person__media > img {
    width: 100%;
    height: auto;
}

.c-person__name {
    display: block;
    padding-top: 15px;

    color: $color-red;
    font-weight: bold;

    @media (max-width: 750px) {
        font-size: 18px;
    }

    @media (min-width: 751px) {
        font-size: 34px;
    }
}

.c-person__subtitle {
    display: block;

    color: black;

    @media (max-width: 750px) {
        font-size: 13px;
    }

    @media (min-width: 751px) {
        font-size: 20px;
    }
}

.c-person__description {
    display: block;
    padding-top: 25px;

    line-height: 23px;

    @media (max-width: 750px) {
        font-size: 13px;
    }

    @media (min-width: 751px) {
        font-size: 16px;
    }
}
