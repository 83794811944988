@import '_variables.scss';

.o-contact-page {
    padding: 3rem;

    background-color: #fff;

    @media (min-width: 751px) {
        padding: 4rem;
    }
}

.o-contact-page__map-infowindow {
    font-family: 'Agenda', Helvetica, Roboto, Arial, sans-serif;
    text-align: center;

    strong {
        display: block;

        font-weight: bold;
    }

    span {
        display: block;
    }
}

.contact-map {
    width: 100%;
    height: 500px;
}

.contact-navigation {
    width: 100%;
    margin-bottom: 40px;

    &.is-color-red {
        li {
            &:focus,
            &:hover,
            &.active {
                color: $color-red;
            }
        }
    }

    &.is-color-green {
        li {
            &:focus,
            &:hover,
            &.active {
                color: $color-green;
            }
        }
    }

    &.is-color-blue {
        li {
            &:focus,
            &:hover,
            &.active {
                color: $color-blue;
            }
        }
    }

    &.is-color-yellow {
        li {
            &:focus,
            &:hover,
            &.active {
                color: $color-yellow;
            }
        }
    }

    .contact-select {
        display: none;
    }

    ul {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 0;

        list-style-type: none;

        li {
            width: 16.6%;
            padding: 10px;
            float: left;
            border-bottom: 1px solid $color-grey;

            text-align: center;

            cursor: pointer;
            user-select: none;
            transition: color 0.2s;

            @media (min-width: 993px) and (max-width: 1099px) {
                font-size: 16px;
                line-height: 32px;
            }

            @media (min-width: 1100px) and (max-width: 1539px) {
                font-size: 18px;
                line-height: 36px;
            }

            @media (min-width: 1540px) {
                font-size: 30px;
                line-height: 60px;
            }

            &.active {
                border-top: 1px solid $color-grey;
                border-bottom: none;

                font-weight: bold;

                &:not(:first-child) {
                    border-left: 1px solid $color-grey;
                }

                &:not(:last-child) {
                    border-right: 1px solid $color-grey;
                }

                span {
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
}

.address-title {
    display: block;
    margin-top: 60px;
    margin-bottom: 60px;

    font-size: 60px;
    line-height: 66px;
    font-weight: bold;
    letter-spacing: -2px;
}

.contact-addresses {
    .contact-address-list {
        .contact-address {
            padding: 25px;

            background-color: $color-grey-light;

            cursor: pointer;
            transition: background-color 0.2s;

            &:not(:last-of-type) {
                margin-bottom: 40px;
            }

            &:hover {
                color: #fff;

                @include is-color(background-color);

                .address-place {
                    color: inherit;
                }
            }

            .address-name {
                font-size: 30px;
                font-weight: bold;
                letter-spacing: -1px;

                transition: color 0.2s;
            }

            .address-place {
                display: block;

                color: #999;
                font-size: 22px;
                line-height: 24px;

                transition: color 0.2s;
                white-space: pre-line;
            }

            .address-phone {
                font-size: 22px;
                line-height: 29px;

                transition: color 0.2s;
            }
        }
    }
}

@media (max-width: 992px) {
    .contact-navigation {
        margin-bottom: 60px;

        position: relative;
    }

    .contact-navigation .contact-select {
        display: block;
        border: 1px solid $body-font-color;

        position: relative;

        cursor: pointer;
    }

    .contact-navigation .contact-select .contact-select-content {
        display: block;
        padding: 10px 30px;

        font-size: 30px;
        line-height: 60px;
        font-weight: bold;
        letter-spacing: -1px;
    }

    .contact-navigation .contact-select .utl-chevron {
        border-color: $body-font-color;

        position: absolute;
        top: 35px;
        right: 25px;
    }

    .contact-navigation ul {
        margin: 0;
        border: 1px solid $color-grey;
        border-radius: 0;

        position: absolute;
        z-index: 1;

        background: #fff;

        box-shadow: none;
    }

    .contact-navigation ul.contact-nav-hide {
        display: none;
    }

    .contact-navigation ul li {
        width: 100%;
        padding: 5px 0;
        padding: 5px 25px;
        float: none;
        border-top: none;
        border-right: none;
        border-bottom: none;
        border-left: none;

        text-align: left;
    }

    .contact-navigation ul li:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid $color-grey;
    }

    .contact-navigation ul li.active {
        border-top: none;
    }

    .contact-navigation ul li.active:not(:last-child) {
        border-right: none;
        border-left: none;
    }

    .contact-navigation ul li:focus {
        background: none;
    }

    .contact-navigation ul li:hover {
        background: none;
    }

    .contact-addresses .contact-address-list .contact-address .address-phone {
        display: block;
        padding-top: 30px;
    }
}

@media (max-width: 480px) {
    .contact-navigation {
        margin-bottom: 30px;
    }

    .contact-navigation .contact-select .contact-select-content {
        padding: 10px 20px;
        padding-top: 12px;

        font-size: 18px;
        line-height: 24px;
    }

    .contact-navigation .contact-select .utl-chevron {
        top: 17px;
        right: 10px;

        background-size: 70%;
    }

    .contact-navigation ul li {
        font-size: 18px;
        line-height: 34px;
        letter-spacing: 0;
    }

    .address-title {
        margin-top: 30px;
        margin-bottom: 30px;

        font-size: 40px;
        line-height: 40px;
        letter-spacing: -1px;
    }

    .contact-addresses .contact-address-list .contact-address {
        margin-bottom: 30px;
        padding: 15px;
    }

    .contact-addresses .contact-address-list .contact-address .address-place {
        font-size: 14px;
        line-height: 16px;
    }

    .contact-addresses .contact-address-list .contact-address .address-name {
        font-size: 20px;
    }

    .contact-addresses .contact-address-list .contact-address .address-phone {
        padding-top: 15px;

        font-size: 14px;
        line-height: 16px;
    }
}
