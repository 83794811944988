@import '_variables.scss';

.c-page-title {
    padding-left: 2rem;
    border-left: 20px solid transparent;

    position: relative;

    color: $color-grey-darker;

    transition: border-width 0.3s;

    @include is-color('border-color');

    @media (max-width: 750px) {
        margin: 4rem 0 2rem;
    }

    @media (min-width: 751px) {
        max-width: 40%;
    }

    &.is-subpage-open {
        @media (min-width: 751px) {
            border-width: 0;
        }
    }
}

.c-page-title__link-wrapper {
    display: inline-block;

    position: relative;
}

.c-page-title__title {
    margin: 0;

    font-size: 4rem;

    transition: font-size 0.5s;

    @media (min-width: 1024px) {
        font-size: 6rem;
        line-height: 5rem;
    }

    .c-page-title.is-subpage-open & {
        @media (min-width: 751px) {
            font-size: 2rem;
        }
    }
}

.c-page-title__description {
    width: 75%;
    margin: 0;
    margin-top: 2rem;

    font-size: 1.5rem;
    font-weight: normal;
    text-shadow: 0 0 100px #333;

    transition: transform 0.5s, opacity 0.5s;

    @media (max-width: 750px) {
        display: none;
    }

    .c-page-title.is-subpage-open & {
        @media (min-width: 751px) {
            opacity: 0;
            transform: translateY(100%);
        }
    }
}

.c-page-title__back {
    position: absolute;

    font-size: 1.5rem;

    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
    transform: translateY(-100%);

    .c-page-title.is-subpage-open & {
        @media (min-width: 751px) {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.c-page-title__link {
    display: block;
    width: 100%;
    height: calc(100% + 2rem);

    position: absolute;
    top: 0;
    left: 0;

    color: inherit;

    &:hover {
        text-decoration: none;
    }
}
