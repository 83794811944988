@import '_variables.scss';
$circle-size: 120px;
$four-column-width: 1135px;

.c-page-link {
    display: block;
    margin: 1rem 0;
    padding: 0 1rem;

    position: relative;

    color: #fff;
    text-align: center;

    @media (min-width: 751px) {
        margin: 2rem 0;
    }

    @media (min-width: $four-column-width) {
        width: 25%;
    }

    &:hover {
        text-decoration: none;
    }

    &.is-dummy {
        height: 0;
        margin: 0;
        visibility: hidden;
    }

    &.is-clicked {
        z-index: 1;
    }
}

.c-page-link__background {
    display: block;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;

    position: absolute;
    top: 60px;
    left: 50%;

    transition: transform 0.3s;
    transform: translate(-50%, -50%) scale(0);

    @include is-color('background-color');

    .c-page-link:hover & {
        transition-timing-function: $circle-expand-timing-function;
        transform: translate(-50%, -50%) scale(1.25);
    }

    .c-page-link.is-clicked & {
        transition: transform 0.5s;
        transform: translate(-50%, -50%) scale(50);
    }
}

.c-page-link__circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $circle-size;
    height: $circle-size;
    margin: 0 auto 20px;
    border: 7px solid white;
    border-radius: 50%;

    position: relative;

    color: white;
    text-align: center;

    img {
        height: 50px;
    }
}

.c-page-link__label {
    display: block;

    position: relative;

    text-transform: uppercase;

    @media (max-width: $four-column-width - 1) {
        width: $circle-size;
    }
}

img.c-page-link__special {
    height: auto !important;
    width: 100%;
    border-radius: 50%;
}