.o-subpage {
    height: 100vh;
    overflow-y: auto;

    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;

    background-color: #fff;

    transition: transform 0.5s;
    transform: translateX(100%);

    box-shadow: #666 0 0 20px 0;

    @media (max-width: 750px) {
        width: 100%;
    }

    @media (min-width: 751px) {
        width: 75%;
    }

    &.subpage-transition-enter-active {
        transform: translateX(0);
    }

    &.subpage-transition-enter-done {
        transform: translateX(0);
    }

    &.subpage-transition-enter {
        transform: translateX(100%);
    }

    &.subpage-transition-enter-active,
    &.subpage-transition-enter-done {
        transform: translateX(0);
    }

    &.subpage-transition-exit {
        transform: translateX(0);
    }

    &.subpage-transition-exit-active,
    &.subpage-transition-exit-done {
        transform: translateX(100%);
    }
}
