.c-content {
    @media (max-width: 750px) {
        padding: 3rem;
    }

    @media (min-width: 751px) {
        padding: 4rem;
    }
}

.c-content__intro {
    margin-bottom: 1rem;

    @media (min-width: 751px) {
        display: flex;
    }
}

.c-content__intro-text {
    @media (min-width: 751px) {
        .c-content__headline + & {
            width: 50%;
            padding-left: 2rem;
        }
    }
}

.c-content__headline {
    margin: 0 0 1rem;

    @media (max-width: 750px) {
        font-size: 2rem;
    }

    @media (min-width: 751px) {
        width: 50%;
        padding-right: 2rem;

        font-size: 3rem;
    }
}

.c-content__content {
    padding-top: 2rem;
    border-top: 1px solid #ccc;

    color: #999;

    @media (min-width: 751px) {
        columns: 2;
        column-gap: 4rem;
    }
}
