@import '_variables.scss';

.o-key-stats-page {
    padding: 3rem;

    @include is-color('background-color');
}

.c-statistic {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    padding: 2rem;
    border-bottom: 1px solid #fff;

    position: relative;

    color: #fff;
    text-align: center;

    @media (min-width: 751px) {
        height: 400px;
    }
}

.c-statistic__image {
    display: block;
    max-width: 100%;
    height: 100%;
    margin-right: auto;
    margin-left: 0;

    opacity: 0.3;

    .c-statistic:nth-child(even) & {
        margin-right: 0;
        margin-left: auto;
    }
}

.c-statistic__text {
    position: absolute;

    &.visible {
        display: block;
    }
}

.c-statistic__suffix {
    font-size: 1.5rem;
}

.c-statistic__number {
    display: block;

    font-size: 5rem;
    font-weight: bold;

    @media (min-width: 451px) {
        font-size: 7rem;
    }

    @media (min-width: 751px) {
        font-size: 10rem;
    }
}
