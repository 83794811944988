@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.o-loadingpage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 1;

    background-color: #000;

    &.o-loadingpage--primary {
        z-index: 2;
    }
}

.o-loadingpage__spinner {
    width: 120px;
    height: 120px;
    border-top: 7px solid rgba(#fff, 0.2);
    border-right: 7px solid rgba(#fff, 0.2);
    border-bottom: 7px solid rgba(#fff, 0.2);
    border-left: 7px solid #fff;
    border-radius: 50%;

    position: relative;

    animation: spin 1.5s infinite linear;
}

.o-loadingpage__logo {
    height: 50px;

    position: absolute;
}
