.o-page {
    @media (min-width: 751px) {
        height: 100vh;
        overflow-x: hidden;
    }

    &.is-hidden {
        visibility: hidden;
    }
}

.o-page__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    height: 100%;
    padding: 2rem;

    position: relative;

    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
}
