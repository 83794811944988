@import '_variables.scss';

.o-awards-page__years-slider {
    height: 70px;

    @media (max-width: 750px) {
        box-shadow: inset 0 -8px 10px -8px $color-grey;
    }
}

.o-awards-page__years-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    position: relative;

    @media (max-width: 750px) {
        width: 33.33%;
    }

    @media (min-width: 751px) {
        width: 200px;
    }

    &:not(:last-child):after {
        content: '';

        width: 1px;
        height: 30px;

        position: absolute;
        top: 50%;
        right: 0;

        background-color: $color-grey;

        transform: translateY(-50%);
    }
}

.o-awards-page__year {
    position: relative;
    top: 3px;

    color: $color-grey;
    font-size: 2rem;
    font-weight: bold;

    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: $color-red;
    }

    .is-selected & {
        color: $color-red;
    }
}

.o-awards-page__awards-slider {
    padding: 2rem 0;

    @media (max-width: 500px) {
        height: 250px;
    }

    @media (min-width: 501px) and (max-width: 900px) {
        height: 350px;
    }

    @media (min-width: 901px) {
        height: 500px;
    }

    .flickity-prev-next-button {
        width: 60px;
        height: 60px;

        background: #fff;

        box-shadow: 0 0 20px 0 #999;

        @media (max-width: 750px) {
            width: 40px;
            height: 40px;
        }

        @media (min-width: 751px) {
            width: 60px;
            height: 60px;
        }

        &:active {
            opacity: 1;
        }

        &.previous {
            left: 0;

            @media (max-width: 750px) {
                margin-left: 1rem;
            }

            @media (min-width: 751px) {
                margin-left: 2rem;
            }
        }

        &.next {
            right: 0;

            @media (max-width: 750px) {
                margin-right: 1rem;
            }

            @media (min-width: 751px) {
                margin-right: 2rem;
            }
        }

        .flickity-button-icon {
            width: 21px;
            height: 21px;

            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);

            @media (max-width: 750px) {
                width: 15px;
                height: 15px;
            }

            @media (min-width: 751px) {
                width: 21px;
                height: 21px;
            }
        }
    }
}

.o-awards-page__awards-cell {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media (max-width: 1024px) {
        width: 25%;
    }

    @media (min-width: 1025px) {
        width: 15%;
    }

    &.is-visible {
        display: flex;
    }

    img {
        width: 100%;

        transition: filter 0.3s, opacity 0.3s, transform 0.3s;

        @media (max-width: 1024px) {
            transform: scale(0.75);
        }

        @media (min-width: 1025px) {
            transform: scale(0.6);
        }
    }

    &.is-selected {
        img {
            z-index: 1;

            filter: drop-shadow(0 0 5px #999);

            @media (max-width: 1024px) {
                transform: scale(1.5);
            }

            @media (min-width: 1025px) {
                transform: scale(1.75);
            }
        }
    }

    &:not(.is-selected) {
        img {
            opacity: 0.3;

            filter: grayscale(100%);
        }
    }
}

.o-awards-page__award {
    display: none;

    &.is-visible {
        display: block;
    }
}
