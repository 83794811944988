@import '_variables.scss';

.c-article {
    padding: 3rem;
}

.c-article__date {
    display: block;
    margin-bottom: 0.5rem;

    color: $color-blue;
    font-size: 18px;
    line-height: 40px;
    letter-spacing: -1px;

    @media (max-width: 480px) {
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0;
    }
}

.c-article__title {
    margin: 0 0 2rem;

    font-size: 3rem;
    font-weight: bold;
}

.c-article__excerpt {
    margin: 0 0 2rem;
    padding-top: 2rem;
    border-top: 1px solid $color-blue;

    color: $color-blue;
    font-size: 2rem;
    font-weight: 300;
}

.c-article__content {
    color: $color-grey-dark;
    font-size: 1.25rem;
    line-height: 1.4;

    white-space: pre-line;

    @media (min-width: 751px) {
        padding: 0 4rem;
    }

    a {
        color: $color-blue;
    }

    img {
        display: block;
        width: calc(100% + 6rem);
        height: auto;
        margin: 2rem -3rem;

        @media (min-width: 751px) {
            width: calc(100% + 14rem);
            margin-right: -7rem;
            margin-left: -7rem;
        }
    }

    figure {
        width: 100% !important;
        margin: 0 0 2rem;

        img {
            margin-bottom: 0.5rem;
        }
    }

    figcaption {
        display: block;

        color: #ccc;
        font-size: 1rem;
    }
}

.c-article__promo {
    margin-top: 4rem;
    display: flex;
    align-items: center;

    @media (min-width: 751px) {
        padding: 0 4rem;
    }

    @media (max-width: 1023px) {
        flex-direction: column;
    }

    &:hover {
        text-decoration: none;
    }
}

.c-article__promo-message {
    color: $color-blue;
    font-size: 2rem;
    font-weight: 300;

    @media (max-width: 1023px) {
        margin-bottom: 1.25rem;

        text-align: center;
    }

    @media (min-width: 1024px) {
        width: 60%;
        margin-right: 1.25rem;
    }

    .c-article__promo:hover & {
        text-decoration: underline;
    }
}

.c-article__promo-button {
    @media (min-width: 1024px) {
        width: 40%;
    }
}
