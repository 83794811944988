.o-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 300px;
    overflow: auto;

    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;

    transition: background-position 0.5s;

    @media (max-width: 750px) {
        background-position: top;
    }

    @media (min-width: 751px) {
        height: 100vh;

        background-attachment: fixed;
    }

    &.is-subpage-open {
        @media (min-width: 751px) {
            background-position-x: -20vw;
        }
    }

    &--mobile-left-align {
        @media (max-width: 750px) {
            background-position-x: left;
        }
    }
}

.o-header__logo {
    width: 120px;
    margin: 1rem;
}
