@import '_variables.scss';

.c-videoplaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.c-videoplaceholder__image {
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.c-videoplaceholder__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;

    position: absolute;

    background-color: rgba(#000, 0.6);

    cursor: pointer;
    transition: width 0.3s, height 0.3s;
    transition-timing-function: $circle-expand-timing-function;

    &:hover {
        width: 180px;
        height: 180px;
    }

    img {
        height: 50px;
        margin-left: 12px;
    }
}
