@import '_variables.scss';

.c-award {
    @media (max-width: 750px) {
        margin: 0 3rem 3rem;
    }

    @media (min-width: 751px) {
        margin: 0 4rem 4rem;
    }

    hr {
        max-width: 350px;
        margin: 2rem auto;
        border: 1px solid #ddd;
    }
}

.c-award__title {
    margin: 0;

    text-align: center;

    @media (max-width: 750px) {
        font-size: 2.5rem;
    }

    @media (min-width: 751px) {
        font-size: 3rem;
    }
}

.c-award__summary {
    text-align: center;
}
