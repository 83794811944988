@import '_variables.scss';

.c-latest-news {
    width: 40%;
    overflow: hidden;

    @media (max-width: 750px) {
        display: none;
    }
}

.c-latest-news__container {
    display: flex;
    align-items: center;
    padding: 1rem;

    background-color: #fff;

    transition: transform 0.5s;
    transform: translateY(100%);

    .c-latest-news.is-open & {
        transform: translateY(0);
    }

    a {
        color: $body-font-color;
    }
}

.c-latest-news__label {
    margin-right: 1rem;
    padding: 1rem;
    border-right: 1px solid #e6e6e6;

    font-weight: bold;

    white-space: nowrap;
}
