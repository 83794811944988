@import '~flickity/css/flickity.css';

@import '_variables.scss';

@import '_utilities.scss';

@font-face {
    font-family: 'Agenda';
    font-weight: normal;

    src: url('./fonts/Agenda-Light.woff') format('woff');
}

@font-face {
    font-family: 'Agenda';
    font-weight: bold;

    src: url('./fonts/Agenda-Bold.woff') format('woff');
}

* {
    box-sizing: border-box;

    -webkit-overflow-scrolling: touch;
}

body {
    overflow-x: hidden;

    color: $body-font-color;
    font-family: 'Agenda', Helvetica, Roboto, Arial, sans-serif;
}

p {
    margin: 0 0 1rem;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.page-transition-group {
    position: relative;
}

.page-wrapper {
    width: 100%;
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

.page-transition-enter {
    z-index: 2;

    opacity: 0.01;

    &.page-transition-enter-active {
        opacity: 1;
        transition: opacity 0.5s;
    }
}

.main-container {
    max-width: 1200px;
    margin: 0 auto;
}

.full-container {
    width: 100%;

    background: white;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 70px;
    padding-left: 70px;

    background: white;

    &.container-orange {
        background: #da4f3f;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;

    &.list {
        margin-right: -35px;
        margin-left: -35px;
    }
}

.column-1 {
    flex: 0 0 10%;
    max-width: 10%;
}

.column-2 {
    flex: 0 0 20%;
    max-width: 20%;
}

.column-3 {
    flex: 0 0 30%;
    max-width: 30%;
}

.column-4 {
    flex: 0 0 40%;
    max-width: 40%;
}

.column-5 {
    flex: 0 0 50%;
    max-width: 50%;
}

.column-6 {
    flex: 0 0 60%;
    max-width: 60%;
}

.column-7 {
    flex: 0 0 70%;
    max-width: 70%;
}

.column-8 {
    flex: 0 0 80%;
    max-width: 80%;
}

.column-9 {
    flex: 0 0 90%;
    max-width: 90%;
}

.column-10 {
    flex: 0 0 100%;
    max-width: 100%;
}

@media (max-width: 992px) {
    .m-column-1 {
        flex: 0 0 10%;
        max-width: 10%;
    }

    .m-column-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .m-column-3 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .m-column-4 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .m-column-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .m-column-6 {
        flex: 0 0 60%;
        max-width: 60%;
    }

    .m-column-7 {
        flex: 0 0 70%;
        max-width: 70%;
    }

    .m-column-8 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .m-column-9 {
        flex: 0 0 90%;
        max-width: 90%;
    }

    .m-column-10 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .container {
        padding-right: 60px;
        padding-left: 60px;
    }
}

@media (max-width: 480px) {
    .s-column-1 {
        flex: 0 0 10%;
        max-width: 10%;
    }

    .s-column-2 {
        flex: 0 0 20%;
        max-width: 20%;
    }

    .s-column-3 {
        flex: 0 0 30%;
        max-width: 30%;
    }

    .s-column-4 {
        flex: 0 0 40%;
        max-width: 40%;
    }

    .s-column-5 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .s-column-6 {
        flex: 0 0 60%;
        max-width: 60%;
    }

    .s-column-7 {
        flex: 0 0 70%;
        max-width: 70%;
    }

    .s-column-8 {
        flex: 0 0 80%;
        max-width: 80%;
    }

    .s-column-9 {
        flex: 0 0 90%;
        max-width: 90%;
    }

    .s-column-10 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .container {
        padding-right: 30px;
        padding-left: 30px;
    }
}
