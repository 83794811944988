@import '_variables.scss';

.c-news-link {
    display: block;
    margin-bottom: 2rem;

    color: $body-font-color;

    @media (min-width: 601px) and (max-width: 750px), (min-width: 901px) {
        display: flex;
        max-height: 200px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:hover {
        text-decoration: none;
    }
}

.c-news-link__image {
    img {
        display: block;
        width: 100%;
        height: 100%;

        object-fit: cover;
    }

    @media (min-width: 601px) and (max-width: 750px), (min-width: 901px) {
        width: 50%;
        max-width: 350px;
    }
}

.c-news-link__content {
    padding: 1rem;

    background-color: $color-grey-light;

    transition: background-color 0.2s;

    @media (min-width: 601px) and (max-width: 750px), (min-width: 901px) {
        flex: 1;
        width: 50%;
        padding: 1.5rem;
    }

    .c-news-link:hover & {
        color: #fff;
    }

    .c-news-link.is-color-red:hover & {
        background-color: $color-red;
    }

    .c-news-link.is-color-green:hover & {
        background-color: $color-green;
    }

    .c-news-link.is-color-blue:hover & {
        background-color: $color-blue;
    }

    .c-news-link.is-color-yellow:hover & {
        background-color: $color-yellow;
    }
}

.c-news-link__date {
    display: block;
    margin-bottom: 1rem;

    transition: color 0.2s;

    @include is-color-parent(color);

    .c-news-link:hover & {
        color: #fff;
    }
}

.c-news-link__title {
    display: block;

    transition: color 0.2s;

    @media (min-width: 751px) {
        font-size: 1.5rem;
    }
}

.c-news-link__icon {
    margin-top: 0.5rem;

    color: #fff;
    font-size: 1.5rem;

    opacity: 0;
    transition: opacity 0.2s;

    @media(min-width: 901px) {
        .c-news-link:hover & {
            opacity: 1;
        }
    }
}
