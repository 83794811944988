@import '_variables.scss';

.c-career {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    padding: 2rem;

    background-color: $color-grey-light;
}

.c-career__title {
    width: 100%;
    margin-top: 0;

    font-size: 2rem;
    font-weight: bold;
}

.c-career__info {
    padding-right: 2rem;

    @media(min-width: 901px) {
        width: 50%;
    }

    span {
        display: block;
        margin-bottom: 1rem;
    }
}

.c-career__content {
    white-space: pre-wrap;

    @media(max-width: 900px) {
        margin-bottom: 2rem;
    }

    @media(min-width: 901px) {
        width: 50%;
    }
}

.c-career__button {
    display: inline-block;
    margin: 0 auto;
    padding: 1rem;
    border: none;

    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;

    background-color: $color-blue;

    cursor: pointer;

    &--mobile {
        @media(max-width: 900px) {
            display: inline-block;
        }

        @media(min-width: 901px) {
            display: none;
        }
    }

    &--desktop {
        @media(max-width: 900px) {
            display: none;
        }

        @media(min-width: 901px) {
            display: inline-block;
        }
    }
}
