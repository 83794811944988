@import '_variables.scss';

.our-process-container {
    padding: 60px 0;

    &:not(:last-child) {
        border-bottom: 2px solid #ccc;
    }

    .our-process-title {
        display: block;
        margin-bottom: 2rem;

        color: $color-grey-darker;
        font-size: 60px;
        line-height: 60px;
        font-weight: bold;
    }

    .our-process-content {
        padding-left: 30px;

        span {
            display: block;

            color: #000;
            font-size: 22px;
            line-height: 29px;
        }

        .our-process-content-grey {
            color: #ccc;
            font-size: 22px;
            line-height: 29px;
        }
    }
}

.our-process-subtitle {
    font-size: 1.5rem;

    &.is-color-red {
        a {
            color: $color-red;
        }
    }

    &.is-color-green {
        a {
            color: $color-green;
        }
    }

    &.is-color-blue {
        a {
            color: $color-blue;
        }
    }

    &.is-color-yellow {
        a {
            color: $color-yellow;
        }
    }
}

.video-container {
    height: 500px;
}

@media (max-width: 992px) {
    .our-process-container {
        .our-process-content {
            padding: 0;

            span {
                font-size: 18px;
            }

            .our-process-content-grey {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 480px) {
    .our-process-container {
        padding: 30px 0;

        .our-process-title {
            font-size: 40px;
            line-height: 40px;
        }

        .our-process-content {
            padding: 0;

            span {
                font-size: 14px;
                line-height: 22px;
            }

            .our-process-content-grey {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}
