.c-menu-slide {
    position: relative;
    z-index: 1; /* To cover footer when page link is open */

    background-position: center;
    background-size: cover;

    @media (min-width: 751px) {
        width: 60%;
        height: 100vh;

        position: absolute;
        top: 0;
        right: 0;

        background-image: none !important; /* Necessary to remove background image in inline styles */

        transform: translateX(100%);
    }
}

.c-menu-slide__background {
    width: 60%;
    height: 100vh;

    position: fixed;
    top: 0;
    right: 0;

    background-position: center;
    background-size: cover;

    transform: translateX(100%);

    @media (max-width: 750px) {
        height: 0;
    }
}

.c-menu-slide,
.c-menu-slide__background {
    transition: transform 0.5s;

    &.is-open {
        transform: translateX(0);
    }
}
