@import '_variables.scss';

.o-footer {
    position: relative;

    color: #fff;

    background-color: $color-grey-darkerer;

    @media (min-width: 751px) {
        display: flex;

        background-color: rgba(0, 0, 0, 0.8);
    }

    &.o-footer.o-footer--subpage {
        color: $body-font-color;

        background-color: $color-grey-light;
    }
}

.o-footer__icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;

    position: relative;

    font-size: 1.5rem;

    background-color: $color-grey-darker;

    @media (min-width: 751px) {
        width: 40%;
    }

    .o-footer.o-footer--subpage & {
        display: none;
    }
}

.o-footer__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;

    &:before {
        content: '';

        width: 50px;
        height: 50px;
        border-radius: 50%;

        position: absolute;

        background-color: $color-grey-darkerer;

        transition: transform 0.3s;
        transition-timing-function: $circle-expand-timing-function;
    }

    &:hover:before {
        transform: scale(1.5);
    }

    svg {
        position: relative;
    }
}

.o-footer__separator {
    width: 2px;
    height: 100px;

    background-color: #292829;
}

.o-footer__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2rem;

    .o-footer.o-footer--subpage & {
        @media (max-width: 750px) {
            padding: 3rem;
        }

        @media (min-width: 751px) {
            padding: 4rem;
        }
    }
}

.o-footer__logo {
    width: 120px;
    margin-bottom: 3rem;
}

.o-footer__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    a {
        color: #fff;

        .o-footer.o-footer--subpage & {
            color: $body-font-color;
        }
    }
}

.o-footer__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-bottom: 2rem;

    @media (min-width: 751px) {
        width: 30%;

        &:last-of-type {
            flex: 1;
        }
    }

    a {
        display: inline-block;
        margin-bottom: 0.25rem;

        color: #fff;
    }
}

.o-footer__address {
    width: 100%;
    max-width: 300px;

    color: $color-grey;
    font-size: 0.9rem;

    @media (min-width: 751px) {
        width: auto;
    }

    .o-footer.o-footer--subpage & {
        color: inherit;
    }

    span {
        display: block;
        margin-bottom: 0.05rem;
    }

    a {
        color: inherit;
    }
}

.o-footer__copyright {
    padding-top: 0.5rem;
    border-top: 1px solid $color-grey-dark;

    color: $color-grey-dark;
    text-align: right;

    .o-footer.o-footer--subpage & {
        border-color: $color-grey;

        color: $color-grey;
    }
}
